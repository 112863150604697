<template>
  <skeleton-o-c-detail-component-global v-if="bSkeleton" />
  <div v-else class="contant-oc-detail-global global-content-detail-all">
    <div class="global-content-detail" >
    <div ref="iHeaderDetail">
      <header-detail-component-global :sTextDetail="sTextDetail" :sBackHref="sBackHref" :setDialog="setDialog"
        :sTextBtnAdd="sTextBtnAdd" :bAdminPermissions="bAdminPermissions" :sClassBtnAdd="sClassBtnAdd" :oItem="oItem"
        @setDialogAdd="setDialogConfirmationOfPaid" @setDialogConfirmCancel="setDialogConfirmationGlobal" />
      <v-breadcrumbs :items="itemsBreadcrumbs" divider="/"></v-breadcrumbs>
    </div>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Folio</p>
            <p class="text-information">
              {{ oItem.sReference }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Nombre del proveedor</p>
            <p class="text-information">
              <v-icon v-show="bToProvider" @click="goToUser(oItem.oVendor.sVendorId)"
                :class="bToProvider ? 'text-icon-launch' : ''">mdi-launch</v-icon>
              <span @click="goToUser(oItem.oVendor.sVendorId)" :class="bToProvider ? 'text-information-link' : ''">
                {{ oItem.oVendor.sName }}
              </span>
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Placa del vehículo</p>
            <p v-if="
              oItem.oDriver.sLicensePlate !== 'N/A' ||
              oItem.oDriver.sLicensePlate !== ''
            " class="text-information">
              {{ oItem.oDriver.sLicensePlate }}
            </p>
            <p v-else class="text-data-empty">
              {{ sEmptyData }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">
              Nombre del transportista
            </p>
            <p v-if="
              oItem.oDriver.sFullName !== '' ||
              oItem.oDriver.sFullName !== null
            " class="text-information">
              {{ oItem.oDriver.sFullName }}
            </p>
            <p v-else class="text-data-empty">
              {{ sEmptyData }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Estado de la orden de compra</p>
            <p class="text-information">
              {{ oItem.oPurchaseOrderStatus.sName }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Fecha y hora de creación</p>
            <p class="text-information">
              {{ oItem.tCreatedAt }}
            </p>
          </v-col>
          <v-col v-if="!bOPPendingDetail" cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Monto a pagar</p>
            <p class="text-information">
              $
              {{ formatMoneyGlobal(oItem.dTotalAmount ? oItem.dTotalAmount : 0) }}
            </p>
          </v-col>
          <v-col v-if="!bOPPendingDetail" cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Fecha y hora de pago</p>
            <p v-if="bPaid" class="text-information">
              {{ oItem.oPurchaseOrderStatus.tLastStatusChange }}
            </p>
            <p v-else class="text-data-empty">
              {{ sEmptyData }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <p class="text-title">Pesaje inicial del camión (Kg)</p>
            <p v-if="oItem.dInitialWeight" class="text-information">
              {{ formatMoneyGlobal(oItem.dInitialWeight) }} Kg
            </p>
            <p v-else class="text-data-empty">
              {{ sEmptyData }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-global mt-9"></v-divider>
        <div class="content-title-add-material-detail-oc">
          <p class="text-title-table">Materias primas y pesaje</p>
          <v-spacer></v-spacer>
        </div>
        <!-- <no-data-text-component-global
            sTextEmpty="una nueva materia prima"
            :iHeight="screenHeight"
            v-if="bEmptyTable"
          /> -->
        <!-- v-else -->
        <v-data-table v-show="!isMobile" mobile-breakpoint="0" :headers="aHeadersRawMaterials" :disable-sort="true"
          :items="aRawMaterial" :hide-default-footer="true" no-data-text="No hay información disponible para mostrar."
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }" class="global-table">
          <template v-slot:[`item.sKey`]="{ item }">
            <div class="content-color-id-raw-material">
              <div :style="{
                'background-color': '#' + item.sColorKey + '!important',
              }" class="color-raw-material"></div>
              <span>{{ item.sKey }}</span>
            </div>
          </template>
          <template v-slot:[`item.dWeightRawMaterial`]="{ item }">
            <span>{{ formatMoneyGlobal(item.dWeightRawMaterial) }}
              {{ item.sMeasurementUnitRawMaterial }}</span>
          </template>
          <template v-slot:[`item.dUnitPriceRawMaterial`]="{ item }">
            <span>${{ formatMoneyGlobal(item.dUnitPriceRawMaterial) }} MXN</span>
          </template>
          <template v-slot:[`item.dSubtotalRawMaterial`]="{ item }">
            <span>${{ formatMoneyGlobal(item.dSubtotalRawMaterial) }} MXN</span>
          </template>
          <template v-slot:footer>
            <footer-table-component-global :oItem="oItem" @setDialogConfirmationGlobal="setDialogConfirmationGlobal"
              :bAdminPermissions="bAdminPermissions" :bCloseBtnRawMaterial="bCloseBtnRawMaterial" />
          </template>
        </v-data-table>
        <table-mobile-raw-material-o-c-component v-show="isMobile" @changeTable="getEmitOcDetail"
          @setItemDialogConfirmationGlobal="setDialogConfirmationGlobal" :aTable="aRawMaterial"
          :headers="aHeadersRawMaterials" :oItem="oItem" :bAdminPermissions="bAdminPermissions" />
          
      </v-container>
    </div>
    <!-- <footer-component-global /> -->
    <dialog-confirmation-of-paid-component-global @setDialogConfirmationOfPaid="setDialogConfirmationOfPaid"
      :bDialogConfirmationOfPaid="bDialogConfirmationOfPaid" :oPurchaseOrder="oItem" />
    <dialog-action-confirm-global @setDialogActionConfirmGlobal="setDialogConfirmationGlobal"
      :bDialogActionConfirm="bDialogConfirmationGlobal" :oDialogConfirm="oDialogConfirmGlobal" />
    <!-- setDialogConfirmCancel -->
  </div>
</template>

<script>
export default {
  props: {
    sApiGetOC: String,
    aHeadersRawMaterials: Array,
    sTypeItem: Number,
    sTextTitle: String,
    sTextDetail: String,
    sBackHref: String,
    setDialog: String,
    sTextBtnAdd: String,
    bAdminPermissions: Boolean,
    sClassBtnAdd: String,
    itemsBreadcrumbs: Array,
    toProvider: String,
    bToProvider: { type: Boolean, default: true },
  },
  data() {
    return {
      screenHeight: 0,
      heightMax: 0,
      bSkeleton: true,
      bLoadingTable: true,
      bEmptyTable: false,
      oItem: {},
      aRawMaterial: [],
      isMobile: false,
      bDialogConfirmationOfPaid: false,
      bDialogConfirmationRawMaterial: false,
      bDialogConfirmationGlobal: false,
      bDialogConfirmCancel: false,
      oDialogConfirmCancel: {
        active: true,
        textTitleActionConfirm: "",
        textDescriptionActionConfirm: "",
        textQuestionActionConfirm: "",
        returnToViewActionConfirm: false,
        apiActionConfirm: "",
      },
      oDialogConfirmRawMaterial: {},
      oDialogConfirmGlobal: {},
      bCreatedOrCanceled: false,
      bPaid: false,
      sEmptyData: "Sin información.",
      bCloseBtnRawMaterial: false,
    };
  },
  beforeMount() {
    this.getOCDetail();
  },
  mounted() {
    this.matchHeight();
    this.handleResize();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.addEventListener("resize ", this.matchHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    matchHeight() {
      if (this.$refs.iHeaderDetail !== undefined) {
        this.heightMax = this.$refs.iHeaderDetail.clientHeight;
        this.heightMax = this.heightMax + 123;
      }
      if (window.innerWidth > 599) {
        this.isMobile = false;
        this.screenHeight = window.innerHeight - 100;
      } else {
        this.isMobile = true;
        this.screenHeight = window.innerHeight - 100;
      }
    },
    handleResize: function () {
      this.matchHeight();
    },
    getEmitOcDetail(obj) {
      this.getOCDetail();
    },
    getOCDetail() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${this.sApiGetOC}${this.$route.params.idOC}`, config, payload)
            .then((response) => {
              this.oItem = response.data.result;
              this.$store.commit("setTypeActor",this.oItem.oVendor.sType);
              this.bCreatedOrCanceled =
                this.oItem.oPurchaseOrderStatus.sPurchaseOrderStatusId ===
                "42260d41-7c0e-49a8-930b-7527d27457a3" ||
                this.oItem.oPurchaseOrderStatus.sPurchaseOrderStatusId ===
                "17008296-f494-4ea6-a83f-be7e1f162a63";

              this.bPaid =
                this.oItem.oPurchaseOrderStatus.sPurchaseOrderStatusId ===
                "caa7c0d5-aa8b-4101-bc46-f92fe8a93d44";
              this.$store.commit("setsNameDetail", "" + this.oItem.sReference);
              this.$store.commit("refresher", false);
              if (!this.bCreatedOrCanceled) {
                this.getRawMaterials();
              } else {
                this.aRawMaterial = [];
                this.bSkeleton = false;
                this.bLoadingTable = false;
                this.bEmptyTable = this.aRawMaterial.length < 1;
                this.$store.commit("refresher", false);
              }
            })
            .catch((error) => {
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        };
    },
    getRawMaterials() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          let sLimitedOrCompleted = this.getLimitedOrCompleted();
          const payload = {},
            config = {
              headers: {
                Authorization: "Bearer " + this.$store.state.sToken,
              },
            };
          DB.get(
            `${this.sApiGetOC}${this.$route.params.idOC}/${sLimitedOrCompleted}`,
            config,
            payload
          )
            .then((response) => {
              this.aRawMaterial = response.data.results.map((e, i) => {
                return {
                  ...e,
                  dNumTransation: i + 1,
                  sCodeRawMaterial: e.oRawMaterial.sCode,
                  sNameRawMaterial: e.oRawMaterial.sName,
                  dWeightRawMaterial: e.dNetWeight ? e.dNetWeight : 0,
                  sMeasurementUnitRawMaterial: e.oMeasurementUnit.sCode,
                  dUnitPriceRawMaterial: e.dUnitPrice,
                  dSubtotalRawMaterial: e.dSubtotal ? e.dSubtotal : 0,
                  sManagerRawMaterial: e.oSpecialist.sFullname,
                };
              });
              this.bSkeleton = false;
              this.bLoadingTable = false;
              this.bEmptyTable = this.aRawMaterial.length < 1;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getLimitedOrCompleted() {
      if (
        this.$route.name === "OPGeneralDetail" ||
        this.$route.name === "OPPayableDetail"
      ) {
        return "completed";
      } else {
        return "limited";
      }
    },
    goToUser(sUserId) {
      if (this.bToProvider) {
        this.$router.push(this.toProvider + `/${sUserId}`).catch(() => { });
      }
    },
    setDialogConfirmationOfPaid() {
      this.bDialogConfirmationOfPaid = !this.bDialogConfirmationOfPaid;
    },
    setDialogConfirmCancel() {
      this.oDialogConfirmCancel = {
        active: true,
        textTitleActionConfirm: "Cancelar órden de compra",
        textDescriptionActionConfirm:
          "La cancelación de una orden de compra es una acción irreversible. ",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con la cancelación de la orden de compra: ${this.oItem.sReference} `,
        returnToViewActionConfirm: false,
        apiActionConfirm: `${URI}/api/sp/v1/purchase-orders/general/${this.$route.params.idOC}/`,
        iTypeAction: 4, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: {},
      };
      this.bDialogConfirmCancel = !this.bDialogConfirmCancel;
    },
    setDialogConfirmationAddRawMaterial(sValueRawMaterial) {
      this.oDialogConfirmRawMaterial = {
        active: true,
        textTitleActionConfirm: "Agregar materia prima",
        textDescriptionActionConfirm:
          "El agregar una materia prima a una orden de compra es una acción irreversible.",
        textQuestionActionConfirm: `Acepto la responsabilidad y deseo proseguir con el agregado de una materia a la orden de compra: ${this.oItem.sReference} `,
        returnToViewActionConfirm: true,
        apiActionConfirm: `${URI}/api/sp/v1/purchase-orders/pending-identify/${this.$route.params.idOC}`,
        iTypeAction: 1, //1= Post , 2= put , 3= patch , 4=delete , 5 = get
        oParams: {
          sRawMaterialId: sValueRawMaterial,
        },
      };
      this.bDialogConfirmationGlobal = !this.bDialogConfirmationRawMaterial;
    },
    setDialogConfirmationGlobal(oItem) {
      this.oDialogConfirmGlobal = oItem;
      if (this.oDialogConfirmGlobal === undefined) {
        this.bCloseBtnRawMaterial = !this.bCloseBtnRawMaterial;
      }
      this.bDialogConfirmationGlobal = !this.bDialogConfirmationGlobal;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bOPPendingDetail() {
      return this.$route.name === "OPPendingDetail";
    },
  },
  watch: {
    refreshTable: function () {
      this.getOCDetail();
    },
  },
};
</script>

<style scoped>
.content-title-add-material-detail-oc {
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
}

.text-title {
  margin-bottom: 2px;
  color: var(--primary-color-text-title-detail);
  font-family: "pop-Regular";
  opacity: 1;
  letter-spacing: 0px;
}

.text-information {
  margin-bottom: 5px;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
}

.text-title-table {
  text-align: start;
  letter-spacing: 4.8px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  /* margin-right: 10px; */
  font-family: "pop-Bold";
  color: var(--primary-color-color-title-information);
}

.content-btn-add-material-detail-oc {
  width: 200px;
}

.content-color-id-raw-material {
  display: flex;
  justify-content: start;
  width: 100%;
}

.color-raw-material {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  margin-top: 2px;
  margin-right: 10px;
  border: 1px solid var(--primary-color-text);
}

.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}
</style>